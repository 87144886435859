import React, { DispatchWithoutAction, FC, useEffect, useState } from "react";
import "./App.css";
import "antd/dist/reset.css";
import {
  ShowPopupParams,
  useShowPopup,
  useThemeParams,
} from "@vkruglikov/react-telegram-web-app";
import {
  Button,
  Col,
  Collapse,
  ConfigProvider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  theme,
  Typography,
} from "antd";
import { useTelegram } from "./TelegramProvider";
import {
  ExchangeBaseResponse,
  ExchangeCurrencyRate,
  ExchangeSettingsResponse,
  OrderBaseResponse,
} from "./Api/ExchangeApi";
import { stringify } from "querystring";
const { Option } = Select;
const styleCol: React.CSSProperties = { padding: "8px" };

interface ExchangeModel {
  userName: string;
  phone: string;
  start: number;
  end: number;
  minimalAmount: number | undefined;
  startCurrency: string;
  endCurrency: string;
  fromDropDown: Array<string>;
  toDropDown: Array<string>;
  rate: number;
  rateRound: number;
  isReverse: boolean;
  startUpdate: boolean;
}

const App: FC<{
  onChangeTransition: DispatchWithoutAction;
}> = ({ onChangeTransition }) => {
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetch("https://crafex-api.zeexs.dev/Exchange")
      .then((response) => response.json())
      .then((data) => {
        updateExchange(data);
      });
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  function updateExchange(update: ExchangeBaseResponse) {
    if (!update.isSuccess) {
      return;
    }
    setServerData(update.data);
    setLoading(false);
  }

  const showPopup = useShowPopup();
  const [popupState, setPopupState] = useState<
    Pick<ShowPopupParams, "title" | "message">
  >({
    title: "test",
    message: "teset",
  });
  const [colorScheme, themeParams] = useThemeParams();
  const { user, webApp } = useTelegram();
  const [serverData, setServerData] = useState<
    Array<ExchangeSettingsResponse> | undefined
  >(undefined);
  
  const [exchangeState, setExchangeState] = useState<ExchangeModel>({
    end: 100,
    endCurrency: "USD",
    fromDropDown: ["USD"],
    isReverse: false,
    minimalAmount: 100,
    rate: 1,
    rateRound: 1,
    start: 100,
    startCurrency: "USD",
    toDropDown: ["USD"],
    startUpdate: true,
    phone: "",
    userName: user?.username ?? "",
  });
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (serverData === undefined) {
      return;
    }
    var currency = serverData[0];
    var toCurrency = currency?.currencyList[0];
    var exchangeModel = toCurrency?.exchanges[0];
    setExchangeState({
      startCurrency: currency!.currency,
      endCurrency: toCurrency!.currency,
      minimalAmount: exchangeModel.minimalAmount,
      end: 100,
      rate: 1,
      fromDropDown: serverData.map((x) => x.currency),
      isReverse: false,
      rateRound: 1,
      start: 100,
      toDropDown: serverData.map((x) => x.currency),
      startUpdate: true,
      phone:"",
      userName:""
    });
  }, [serverData]);

  useEffect(() => {
    var currency = serverData?.find(
      (z) => z.currency == exchangeState.startCurrency
    );
    if (currency !== undefined) {
      setExchangeState((params) => ({
        ...params,
        toDropDown: currency!.currencyList.map((x) => x.currency),
      }));
    }
  }, [exchangeState?.startCurrency]);

  useEffect(() => {
    var currency = serverData?.find(
      (z) => z.currency == exchangeState.startCurrency
    );
    if (currency !== undefined) {
      var toCurrency = currency.currencyList.find(
        (z) => z.currency == exchangeState.endCurrency
      );
      if (toCurrency !== undefined) {
        var rate = toCurrency.exchanges[0];
        setExchangeState((params) => ({
          ...params,
          end: Number.parseFloat((params.start * rate.buyRate).toFixed(2)),
          toDropDown: currency!.currencyList.map((x) => x.currency),
        }));
      }
    }
  }, [exchangeState?.endCurrency]);

  useEffect(() => {
    if (exchangeState.startUpdate) {
      setExchangeState((params) => ({
        ...params,
        end: Number.parseFloat((params.start * params.rate).toFixed(2)),
      }));
    } else {
      setExchangeState((params) => ({
        ...params,
        start: Number.parseFloat((params.end / params.rate).toFixed(2)),
      }));
    }
  }, [
    exchangeState?.startUpdate,
    exchangeState?.start,
    exchangeState?.end,
    exchangeState?.rate,
  ]);

  useEffect(() => {
    var currency = serverData?.find(
      (z) => z.currency == exchangeState.startCurrency
    );
    if (currency !== undefined) {
      var findCurrencyTo = currency.currencyList.find(
        (x) => x.currency === exchangeState.endCurrency
      );
      if (findCurrencyTo === undefined) {
        var currencyTo = currency.currencyList[0];
        var rate = currencyTo.exchanges[0];
        setExchangeState((params) => ({
          ...params,
          endCurrency: currencyTo.currency,
          rate: rate.buyRate,
          rateRound: rate.buyRateRound,
          minimalAmount: rate.minimalAmount,
          isReverse: rate.isReverse,
        }));
      } else {
        var rate = findCurrencyTo.exchanges[0];
        setExchangeState((params) => ({
          ...params,
          rate: rate.buyRate,
          rateRound: rate.buyRateRound,
          minimalAmount: rate.minimalAmount,
          isReverse: rate.isReverse,
        }));
      }
    }
  }, [exchangeState?.toDropDown]);

  function sendOrder(value: any) {
    if (exchangeState === undefined) {
      return;
    }
    setLoading(true);
    var message =
      `Меняем ${exchangeState.startCurrency}  => ${exchangeState.endCurrency} \n` +
      `Отдаете: ${exchangeState.start} ${exchangeState.startCurrency}\n` +
      `Получите: ${exchangeState.end} ${exchangeState.endCurrency}\n` +
      `По курсу: ${exchangeState.rate.toFixed(exchangeState.rateRound)}`;
    var popUpSettings = {
      message: message,
      title: "Отправить заявку",
    };
    setPopupState(value);
    showPopup({
      ...popUpSettings,
      buttons: [
        {
          type: "ok",
          id: "1",
        },
        {
          type: "destructive",
          text: "Отмена",
          id: "2",
        },
      ],
    })
      .then((s) => {
        if (s === "1") {
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              accept: "text/plain",
            },
            body: JSON.stringify({
              initData: webApp!?.initData,
              version: webApp!?.version,
              platform: webApp!.platform,
              fromAmount: exchangeState.start,
              fromCurrency: exchangeState.startCurrency,
              toCurrency: exchangeState.endCurrency,
              CustomName: "",
              PhoneNumber: "",
            }),
          };
          fetch("https://crafex-api.zeexs.dev/Order/SendOrder", requestOptions)
            .then((response) => response.json())
            .then((data) => {
              var response = data as OrderBaseResponse;
              if (response!.isSuccess) {
                showPopup({
                  title: "Успешно",
                  message: "Заявка успешно оформлена.",
                }).then((x) => {
                  setLoading(false);
                  window.Telegram.WebApp.close();
                });
              } else {
                showPopup({
                  title: "Ошибка",
                  message: "Не удалось создать заявку.", // "Заявка успешно оформлена.",
                }).then((x) => {
                  setLoading(false);
                });
              }
            })
            .catch((e) => {
              showPopup({
                title: "error",
                message: e,
              }).then((x) => {
                setLoading(false);
              });
            });
        } else {
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        showPopup({
          title: "error",
          message: e,
        });
      });
  }

  function getViewRate() {
    return exchangeState != undefined
      ? (exchangeState?.isReverse
          ? 1 / (exchangeState!.rate / 1)
          : exchangeState!.rate
        ).toFixed(exchangeState!.rateRound)
      : 0;
  }

  function GetContactForm() {
    return (
      <Row>
        <Col flex={10} style={styleCol}>
          <Collapse>
            <Collapse.Panel key={1} header="Контакт для связи с вами">
              <Space direction="vertical">
                <Typography.Text>Telegram</Typography.Text>
                <Input addonBefore="@" value={exchangeState?.userName} onChange={(value) => {
                    setExchangeState((previous) => ({
                      ...previous,
                      userName: value.target.value,
                    }));
                  }}/>
                <Typography.Text>Номер телефона</Typography.Text>
                <Input
                  defaultValue={exchangeState.phone}
                  value={exchangeState.phone}
                  placeholder="+971 55 297 2040"
                  onChange={(value) => {
                    setExchangeState((previous) => ({
                      ...previous,
                      phone: value.target.value,
                    }));
                  }}
                />
                {exchangeState.userName}
              </Space>
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
    );
  }

  function exchangePage() {
    return (
      <Col className="contentWrapper">
        <Row>
          <Typography style={styleCol}>Привет {user?.first_name}</Typography>
        </Row>
        <Row>
          <Col flex={2}>
            <div style={styleCol}>
              <Typography style={styleCol}>Что отдаёте?</Typography>
              <InputNumber
                addonBefore={
                  <Select
                    defaultValue={exchangeState?.startCurrency}
                    value={exchangeState?.startCurrency}
                    onChange={(value) => {
                      setExchangeState((previous) => ({
                        ...previous,
                        startCurrency: value,
                      }));
                    }}
                  >
                    {exchangeState?.fromDropDown.map((x, k) => (
                      <Option key={k} value={x}>
                        {x}
                      </Option>
                    ))}
                  </Select>
                }
                defaultValue={exchangeState?.start}
                value={exchangeState?.start}
                onChange={(value) => {
                  if (value !== null) {
                    setExchangeState((previous) => ({
                      ...previous,
                      start: value,
                      startUpdate: true,
                    }));
                  }
                }}
                stringMode={true}
                size={"large"}
                min={exchangeState?.minimalAmount}
                max={100000000}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                }
              />
            </div>
          </Col>
          <Col flex={2}>
            <div style={styleCol}>
              <Typography style={styleCol}>Что получете?</Typography>
              <InputNumber
                addonBefore={
                  <Select
                    defaultValue={exchangeState?.endCurrency}
                    value={exchangeState?.endCurrency}
                    onChange={(value) => {
                      setExchangeState((previous) => ({
                        ...previous,
                        endCurrency: value,
                      }));
                    }}
                  >
                    {exchangeState?.toDropDown.map((x, k) => (
                      <Option key={k} value={x}>
                        {x}
                      </Option>
                    ))}
                  </Select>
                }
                defaultValue={exchangeState?.end}
                value={exchangeState?.end}
                onChange={(value) => {
                  if (value !== null) {
                    setExchangeState((previous) => ({
                      ...previous,
                      end: value,
                      startUpdate: false,
                    }));
                  }
                }}
                stringMode={true}
                size={"large"}
                min={0}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                }
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col flex={10} style={styleCol}>
            <Typography>Обмен по курсу: {getViewRate()}</Typography>
          </Col>
        </Row>
        <Row>
          <Col flex={10} style={styleCol}>
            <Typography>
              Минимальная сумма обмена {exchangeState?.minimalAmount}{" "}
              {exchangeState?.startCurrency}
            </Typography>
          </Col>
          {/* <Col flex={10} style={styleCol}>Тех поддержка - <Link href="https://t.me/crafex_manager" >@crafex_manager</Link></Col>
          <Col flex={10} style={styleCol}>Менеджер - <Link href="https://t.me/crafex_manager">@crafex_manager</Link></Col> */}
        </Row>
        {user?.username === undefined || user?.username === "" ? (
          GetContactForm()
        ) : (
          <div></div>
        )}
        <Row>
          <Col flex={10} style={styleCol}>
            <Button
              block
              type="primary"
              htmlType="submit"
              disabled={
                exchangeState === undefined ||
                (exchangeState.minimalAmount !== undefined &&
                  exchangeState?.start < exchangeState?.minimalAmount)
              }
              onClick={sendOrder}
            >
              Отправить заявку
            </Button>
          </Col>
        </Row>
        <Row>
          <Col flex={10} style={styleCol}>
            <Button
              block
              href="https://t.me/crafex_manager"
              type="primary"
              htmlType="submit"
            >
              Помощь
            </Button>
          </Col>
        </Row>
      </Col>
    );
  }

  return (
    <div>
      <ConfigProvider
        theme={
          themeParams.text_color
            ? {
                algorithm:
                  colorScheme === "dark"
                    ? theme.darkAlgorithm
                    : theme.defaultAlgorithm,
                token: {
                  colorText: themeParams.text_color,
                  colorPrimary: themeParams.button_color,
                  colorBgBase: themeParams.bg_color,
                },
              }
            : undefined
        }
      >
        <header>
          <Typography style={{ textAlign: "center" }}>
            <Typography.Text strong>CRAFEX EXCHANGE</Typography.Text>
          </Typography>
        </header>
        <Spin spinning={loading}>{exchangePage()}</Spin>
      </ConfigProvider>
    </div>
  );
};

export default App;
